// LandingPage.js
import React from 'react';
import { ReactComponent as LogoBlack } from '../Full_Logo_Black_CMYK.svg';
import { ReactComponent as LogoWhite } from '../Full_Logo_White_CMYK.svg';
import { ReactComponent as LogoGreen } from '../Full_Logo_Green_CMYK.svg';
import { Button, Box, Typography } from '@mui/material';
import './LandingPage.css';


const LandingPage = () => {

  const handleNavigate = () => {
    window.location.href = `/?landingP=true`; // Or your desired route, e.g. '/app' or wherever the app starts
  };

  return (
     <div className="landing-page">
      <div className="landing-page-overlay"></div>
      <div className="landing-page-logo">
        <LogoGreen />
      </div>
      <div className="landing-page-content">
        <h1 className="landing-page-title">
          Connect Your Spotify Music with Festival Artists
        </h1>
        <p className="landing-page-subtitle">
          Discover your favorite songs matching the artists performing at festivals.
        </p>
        <button className="landing-page-button" onClick={handleNavigate}>
          Explore Festivals
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
